/* debug.css */

/* DEBUG */

.debug-container {
  display: inline-block;
  min-width: 120px;
  max-width: max-content;
  min-height: 19px;
  /*  */
  border: 1px solid #ffffff80;
  padding: 8px;
  margin-top: 12px;
  margin-bottom: 20px;
  /*  */
  border-radius: 10px;
  font-family: monospace;
  line-height: 1.4;
  align-items: center;
  justify-content: center;
}

.debug-content {
  margin-top: 2px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 2px;
  font-size: x-small;
}

.debug-label {
  font-size: xx-small;
  font-family: monospace;
  margin-top: -14px;
  margin-bottom: 20px;
}

.debug-spacer {
  height: 60px;
}

/* TO DO */

.todo-container {
  /* display: inline-block; */
  min-width: 80px;
  max-width: max-content;
  min-height: 19px;
  border: 1px solid #ffdd47aa;
  padding: 8px;
  border-radius: 10px;
  font-family: monospace;
  align-items: center;
  justify-content: center;
}

.todo-content {
  margin-top: 4px;
  margin-left: -20px;
  margin-right: 10px;
  font-size: x-small;
  text-align: left;
  color: #ffdd47aa;
  font-style: italic;
}

.todo-label {
  font-size: xx-small;
  font-family: monospace;
  margin-top: 4px;
  color: #ffdd47aa;
}

.todo-spacer {
  height: 160px;
}
