.debug-container {
  border: 1px solid #ffffff80;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  max-width: max-content;
  min-height: 19px;
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 8px;
  font-family: monospace;
  line-height: 1.4;
  display: inline-block;
}

.debug-content {
  margin: 2px 12px;
  font-size: x-small;
}

.debug-label {
  margin-top: -14px;
  margin-bottom: 20px;
  font-family: monospace;
  font-size: xx-small;
}

.debug-spacer {
  height: 60px;
}

.todo-container {
  border: 1px solid #ffdd47aa;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  max-width: max-content;
  min-height: 19px;
  padding: 8px;
  font-family: monospace;
}

.todo-content {
  text-align: left;
  color: #ffdd47aa;
  margin-top: 4px;
  margin-left: -20px;
  margin-right: 10px;
  font-size: x-small;
  font-style: italic;
}

.todo-label {
  color: #ffdd47aa;
  margin-top: 4px;
  font-family: monospace;
  font-size: xx-small;
}

.todo-spacer {
  height: 160px;
}
/*# sourceMappingURL=make.86d9cbf6.css.map */
